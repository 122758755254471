//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ref,
  computed,
  defineComponent,
  watch,
  inject,
} from '@vue/composition-api';
import useRootInstance from '../../theme/shared/useRootInstance';
import { getPriceFilterItemText } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfAppliedFilters',
  components: {
    VfCollapsibleChips: () =>
      import('@vf/ui/components/Atom.CollapsibleChips.vue'),
  },
  props: {
    appliedFilters: {
      type: Array,
      default: () => [],
    },
    filterOptions: {
      type: Array,
      default: () => [],
    },
    facetConfiguration: {
      type: Object,
      default: () => ({}),
    },
    showSeeAll: {
      type: Boolean,
      default: true,
    },
    showReset: {
      type: Boolean,
      default: true,
    },
    translations: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { slots }) {
    const { root } = useRootInstance();

    // TODO: Remove in GLOBAL15-62475
    const isFiltersUiUpdateEnabled = inject('isFiltersUiUpdateEnabled');
    // TODO: Cleanup in GLOBAL15-63799
    const isVansPlpRedesignEnabled = inject('isVansPlpRedesignEnabled');
    const appliedFiltersConfig = root.$themeConfig.appliedFilters;
    const minFiltersToDisplay = appliedFiltersConfig?.minFiltersToDisplay || 2;
    const displayDivider = appliedFiltersConfig?.displayDivider !== false;
    const showExpandIcon = appliedFiltersConfig?.showExpandIcon !== false;
    const seeAll = ref(false);
    const visibleFilters = ref([]);
    const getMinimumVisibleFilters = (filters) => {
      return filters.reduce((acc, item, index) => {
        if (index < minFiltersToDisplay) {
          acc.push(item);
        }
        return acc;
      }, []);
    };

    const getFeatureFlags = inject('getFeatureFlags');
    const filters = computed(() => {
      return visibleFilters.value.map((option) => {
        const featureFlags = getFeatureFlags();
        const [label, ...colors] = getFilterLabel(
          option.code,
          option.value
        ).split(featureFlags.isNewColorDividerEnabled ? ';' : '|');
        option.text =
          option.code === 'price'
            ? getPriceFilterItemText(
                option,
                props.translations,
                root.$formatPrice
              ) || label
            : label;
        option.colors = colors;
        return option;
      });
    });

    watch(
      () => props.appliedFilters,
      (value) => {
        if (isFiltersUiUpdateEnabled?.value) {
          visibleFilters.value = value;
          return;
        }
        if (props.showSeeAll && seeAll.value) {
          visibleFilters.value = value;
        } else {
          visibleFilters.value = getMinimumVisibleFilters(value);
        }
      },
      { immediate: true }
    );

    const getFilterLabel = (code, value) => {
      const filterGroup = props.filterOptions.find(
        (group) => group.code === code
      );
      const filterLabel = filterGroup?.options.find(
        (filter) => filter.id === value
      );

      if (props.facetConfiguration[code]?.displayType === 'range') {
        return getRangeFilterLabel(value);
      }

      return filterLabel?.label || value;
    };

    const getRangeFilterLabel = (value) => {
      const parts = value.slice(1, -1).split(' ');
      const min = root.$formatPrice(parts[0], null, true);

      let max;

      if (parts[2] === '*') {
        let maxDisplayPrice =
          props.translations.priceRangeInputLabels?.maximumDisplayPrice || 300;
        max = root.$formatPrice(maxDisplayPrice, null, true) + '+';
      } else {
        max = root.$formatPrice(parts[2], null, true);
      }

      return `${min} - ${max}`;
    };

    const showDivider = computed(() => {
      if (!displayDivider) {
        return false;
      }
      return (
        (props.showReset && props.appliedFilters.length > 1) ||
        (props.showSeeAll && props.appliedFilters.length > 2)
      );
    });

    const hasCustomResetButtonSlot = computed(() => !!slots['reset-button']);

    const showClearAllFacet = computed(() => {
      return !hasCustomResetButtonSlot.value && props.showReset;
    });

    const toggleSeeAll = () => {
      if (!seeAll.value) {
        visibleFilters.value = props.appliedFilters;
      } else {
        visibleFilters.value = getMinimumVisibleFilters(props.appliedFilters);
      }
      seeAll.value = !seeAll.value;
    };
    return {
      filters,
      seeAll,
      showDivider,
      hasCustomResetButtonSlot,
      showClearAllFacet,
      displayDivider,
      showExpandIcon,
      minFiltersToDisplay,
      toggleSeeAll,
      isFiltersUiUpdateEnabled,
      isVansPlpRedesignEnabled,
    };
  },
});
